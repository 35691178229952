import { DH, DL, LEADERSHIP, CRM_LEADS, HRDI_TEAM, FND_DH } from "../App/Constants";
import { getEnvVariable } from "../App/Helpers";
import { ocpEmailList } from "../types";

const ocpMenuAllowedList = [LEADERSHIP, DH, DL]

const crossDomainAllowed = [LEADERSHIP, DH, FND_DH, CRM_LEADS, HRDI_TEAM]

export const validateOcpMenu = (authorityList) => {
    return authorityList?.some((authority) => ocpMenuAllowedList.includes(authority));
}

export const validateCrossDomainAccess = (authorityList) => {
    return authorityList?.some((authority) => crossDomainAllowed.includes(authority));
}

//Temporarly added the crossDomain validated based on Email Id.
export const validateCrossDomainAccessByEmailId = (emailId) => {
    let crossDomainAllowedEmailList = [];
    if (ocpEmailList && typeof ocpEmailList == 'string') {
        crossDomainAllowedEmailList = ocpEmailList.split(",");
    } else {
        crossDomainAllowedEmailList = ocpEmailList;
    }
    return ((crossDomainAllowedEmailList?.includes(emailId)) && (getEnvVariable() === "QE"));
}

export const priorities = [
    {priorityId: 1, backgroundColor: "#8B0000", label: "JR-Urgent", status: "Urgent"},
    {priorityId: 2, backgroundColor: "#E98339", label :"JR-High", status :"High" },
    {priorityId: 3, backgroundColor: "#e7eb14", label: "JR-Medium", status :"JR Medium"},
    {priorityId: 4, backgroundColor: "#fcf7ae", label :"JR-Low", status :"JR Low"}
];
export const jrStatusColors = [
    {status: "Initiated", label:"JR-Initiated", backgroundColor: "#508dca", textColor:"#fff"},
    {status: "On hold", label:"JR-On hold", backgroundColor: "#b4d6fa", textColor:"#000000"}
];
export const priorityTextColors = {
    1: "#ffffff", 2: "#ffffff", 3: "#000000", 4: "#000000"
};
export const boxColor = {
    "light": "#343F48", "dark": "#ffffff"
};
export const jrStatus = ["initiated", "in process", "approved", "on hold"];

export const minimumJRPositions = 0;

export const employeeStatusColorsProd = [
    { id: 13, label: "In-Separation", backgroundColor: "#BDCDDB", textColor: "#000" },
    { id: "extended-leaves", label: "Extended Leaves", backgroundColor: "#C159AA", textColor: "#FFF" },

]

export const employeeStatusColorsQE = [
    { id: 13, label: "In-Separation", backgroundColor: "#BDCDDB", textColor: "#000" },
    { id: "extended-leaves", label: "Extended Leaves", backgroundColor: "#C159AA", textColor: "#FFF" },

]

export const getEmployeeStatusColorCodesByEnv = () => { 
    return getEnvVariable() === "PROD" ? employeeStatusColorsProd : employeeStatusColorsQE;
}
// Rendering only the Project Status based on the below list
export const projectActiveList = ['Active', 'Support', 'R&D'];

// Determining the width based on the positions for the DH and DL. Might need to move to the Group level in future if the employee API returns groups
export const positions = ['senior department head', 'department head', 'delivery lead', 'delivery manager', 'delivery']

export const projectStatusLabel = {
    active : "Active",
    backup: "Backup",
    backupAlt: "Back up"
}
export const projectStatusCode = [
    { id: 3, label: projectStatusLabel.active, backgroundColor: "#079976", textColor: "#FFF" },
    { id: 4, label: projectStatusLabel.backup, backgroundColor: "#7F5EFF", textColor: "#FFF" }
]
// Need to replace this once the id of the status are mapped
export const projectStatusCodeAlt = [
    { id: 1, label: projectStatusLabel.backupAlt, backgroundColor: "#7F5EFF", textColor: "#FFF" }
]