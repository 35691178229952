export const ClientID = process.env.REACT_APP_CPVN_CLIENT_ID;
export const serviceURL = process.env.REACT_APP_CPVN_API_ENDPOINT;
export const serviceCGPURL = process.env.REACT_APP_CGP_API_ENDPOINT;
export const serviceOCPURL = process.env.REACT_APP_OCP_API_ENDPOINT;
export const hrvineURL = process.env.REACT_APP_HRVN_ENDPOINT;
export const hrvineImagePath = process.env.REACT_APP_HRVN_IMAGE_PATH;
export const ocpEmailList = process.env.REACT_APP_OCP_ACCESS_LIST;
export const envVariable = process.env.REACT_APP_OCP_ENV_VARIABLE;
export const redirectURI = process.env.REACT_APP_REDIRECT_URI
export const railsAPI = process.env.REACT_APP_CPVN_RAILS_API;
export const agvnURL = process.env.REACT_APP_AGVN_API;
export const agvnAccessKey = process.env.REACT_APP_AGVN_ACCESS_KEY;
export const profileGeniusURL = process.env.REACT_APP_PROFILE_GENIUS_URL;