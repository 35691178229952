import React, { useState, useEffect } from "react";
import useDarkMode from "../../hooks/useDarkMode";
import Button from "../../components/bootstrap/Button";
import classNames from "classnames";
import { useFormik, FormikValues } from "formik";
import Modal, { ModalBody, ModalHeader, ModalTitle, ModalFooter } from "../../components/bootstrap/Modal";
import Input from "../../components/bootstrap/forms/Input";
import Select from '../../components/bootstrap/forms/Select';
import Option from '../../components/bootstrap/Option';
import { getSelectedProjectDetails, updateProjectDetail, getSysvineRoles } from "../../common/data/service";
import { TeamRole } from "./types";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Popovers from "../../components/bootstrap/Popovers";
import Icon from "../../components/icon/Icon";
import Alert, { AlertHeading } from '../../components/bootstrap/Alert';
import { validateExpiredToken } from '../../App/Constants';
import { useNavigate } from 'react-router-dom';

const TeamsizeTab = (props: any) => {
  const { projectId, teamDetails, setTeamDetails, setIsLoading, aedAccess } = props;
  const { darkModeStatus } = useDarkMode();
  const [isTeamEdit, setIsTeamEdit] = useState(false);
  const [editIndex, setEditIndex] = useState("");
  const [deleteConfirmationPopup, setDeleteConfirmationPopup] = useState<boolean>(false);
  const [deleteTeamId, setDeleteTeamId] = useState<string>("");
  const [sysvineRoles, setSysvineRoles] = useState([]);
  const [editTeamSize, setEditTeamSize] = useState(false);
  const [addTeamSize, setAddTeamSize] = useState(false);
  const [alert, setAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const navigate = useNavigate();
  const fetchSysvineRoles = () => {
    getSysvineRoles().then((res) => {
      setSysvineRoles(res?.data);
    }).catch((e) => {
      if (!validateExpiredToken(e.message)) {
        navigate('/');
        window.location.reload();
      }
    });
    }
  const calculateTotalTeamSize = () => {
    let total = 0;
    if (teamDetails && teamDetails.roles && teamDetails.roles.length > 0) {
      teamDetails.roles.map((role_list: TeamRole) => (
        total = total + parseInt(role_list.number)
      ))
    }
    return total;
  };

  const formik = useFormik({
    onSubmit: (values) => {
      let isValid = false;
      if (values.numberOfMembers && values.sysvineRole) {
        isValid = true;
      }
      if(isValid){
        setIsLoading(true);
        updateProjectDetail({
          projectId: projectId,
          aedTeamJson: values.aedTeamJson,
        }).catch((e) => {
          if (!validateExpiredToken(e.message)) {
            navigate('/');
            window.location.reload();
          }
        });
        setTeamData();
        handleClose();
      }
    },
        initialValues: {
      aedTeamJson: teamDetails,
      sysvineRole: "",
      numberOfMembers: ""
        },
    validate: (values) => {
      const errors: Partial<FormikValues> = {};
      if (!values.sysvineRole) {
        errors.sysvineRole = "Role is required";
      } else if (values.sysvineRole && teamDetails && teamDetails.roles && teamDetails.roles.length > 0) {
        const roleExists = teamDetails.roles.some((role: { role: string; }, index: string) => {
          if (editTeamSize) {
            return index !== editIndex && role.role === values.sysvineRole;
          } else {
            return role.role === values.sysvineRole;
          }
        });
        if (roleExists) {
          errors.sysvineRole = 'Role already exists';
        }
      }
      if (!values.numberOfMembers) {
        errors.numberOfMembers = "Please provide a valid team size";
      } else if (/^-?\d+$/.test(values.numberOfMembers)) {
        if (parseInt(values.numberOfMembers) <= 0) {
          errors.numberOfMembers = "Please provide a valid team size";
        }
      } else if (/^\d*\.\d+$/.test(values.numberOfMembers)) {
        errors.numberOfMembers = "Please provide a valid team size";
      } else {
        errors.numberOfMembers = "Please provide a valid team size";
      }
      return errors;
    },
    });
  const setTeamData = async () => {
    await getSelectedProjectDetails({ projectId }).then((res) => {
      setTeamDetails(res?.data?.data[0]?.aed_team_json);
      setIsLoading(false);
    })
    setAlert(true);
    setTimeout(() => {
      setAlert(false);
    }, 3000)
  };
  const handleDelete = (index: string) => {
    delete teamDetails.roles[index];
    updateProjectDetail({
      projectId: projectId,
      aedTeamJson: teamDetails,
    }).catch((e) => {
      if (!validateExpiredToken(e.message)) {
        navigate('/');
        window.location.reload();
      }
    });
    setAlertMessage('Deleted Successfully');
  };
  const handleClose = () => {
    setAddTeamSize(false);
    if (isTeamEdit) {
      setIsTeamEdit(false);
    }
    formik.setFieldValue("sysvineRole", "");
    formik.setFieldValue("numberOfMembers", "");
    formik.resetForm();
  };
  const handleSave = () => {
    const newRole = { role: formik.values.sysvineRole, number: formik.values.numberOfMembers };
    const updatedRoles = teamDetails.roles ? [...teamDetails.roles, newRole] : [newRole];
    const aedRole = { roles: updatedRoles };
    formik.setFieldValue("aedTeamJson", aedRole);
    setAlertMessage('Saved Successfully');
  };
  const handleEdit = () => {
    const updatedRoles = [...teamDetails.roles];
    updatedRoles[parseInt(editIndex)] = { role: formik.values.sysvineRole, number: formik.values.numberOfMembers };
    const aedRole = { roles: updatedRoles };
    formik.setFieldValue("aedTeamJson", aedRole);
    setAlertMessage('Updated Successfully')
  };

  useEffect(() => {
    (addTeamSize || deleteConfirmationPopup) ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'unset';
  }, [addTeamSize, deleteConfirmationPopup])

  return (
    <div>
      <div className="card">
        <div className="card-header">
          <div className="row g-3 align-items-center">
            <div className="col d-flex align-items-center">
              <div className="flex-shrink-0">
                <div className="ratio ratio-1x1 aed-icon-box">
                  <div className={classNames("rounded-2 d-flex align-items-center justify-content-center", darkModeStatus ? "bg-lo25-secondary" : "bg-l10-secondary")}>
                    <label
                      className="border-0 bg-transparent me-0"
                      htmlFor="People">
                      <Icon icon="People" size="2x" color='secondary' />
                    </label>
                  </div>
                </div>
              </div>
              <div className="flex-grow-1 ms-3 d-flex justify-content-between align-items-center">
                <div>
                  <div className="fw-bold fs-6 mb-0">
                    {calculateTotalTeamSize() > 0 ? (
                  <h5 className="card-title">Team Size - {calculateTotalTeamSize()}</h5>
                ) : <h5 className="card-title">Team Size</h5>}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-actions">
            {!editTeamSize && aedAccess?.edit &&
              <Button
                icon="Plus"
                color="info"
                onClick={() => {
                  setAddTeamSize(true);
                  fetchSysvineRoles();
                }}>
                  Add
              </Button>
            }
            {aedAccess?.edit && <Button
              color={"dark"}
              isOutline={!darkModeStatus}
              isLight={darkModeStatus}
              className={classNames("text-nowrap", {
                "border-light": !darkModeStatus,
              })}
              onClick={() => {
                setEditTeamSize(!editTeamSize);
                fetchSysvineRoles();
              }}
              icon={editTeamSize ? "Close" : "Edit"}
              isDisable={!editTeamSize && (!teamDetails || !teamDetails.roles || teamDetails.roles.length === 0)}
            >
            </Button>}
          </div>
        </div>
        <div className="card-body">
          <div className="aed-card">
            {teamDetails && teamDetails.roles && teamDetails.roles.length > 0 && (
              <table className="table table-modern table-hover">
                <tbody>
                  {teamDetails.roles.map((role_list: TeamRole, index: string) => (
                    <tr key={index}>
                      <td className="col-lg-3">
                        <div className="aed-table">
                          {role_list.role}
                        </div>
                      </td>
                      <td className="col-lg-7">{role_list.number}</td>
                      <td className="col-lg-2">
                        {editTeamSize &&
                          <div className="col-lg align-items-center">
                            <Popovers trigger='hover' desc='Delete'>
                              <Button
                                isOutline={!darkModeStatus}
                                color="dark"
                                isLight={darkModeStatus}
                                className={classNames("text-nowrap", {
                                  "border-light": !darkModeStatus,
                                })}
                                icon="Delete"
                                onClick={() => {
                                  setDeleteTeamId(index);
                                  setDeleteConfirmationPopup(true)
                                }}
                              >
                              </Button>
                            </Popovers>
                            <Popovers trigger='hover' desc='Edit'>
                              <Button
                                isOutline={!darkModeStatus}
                                color="dark"
                                isLight={darkModeStatus}
                                className={classNames("text-nowrap", {
                                  "border-light": !darkModeStatus,
                                })}
                                icon="Edit"
                                onClick={() => {
                                  formik.setFieldValue("sysvineRole", role_list.role);
                                  formik.setFieldValue("numberOfMembers", role_list.number);
                                  setAddTeamSize(true);
                                  setIsTeamEdit(true);
                                  setEditIndex(index);
                                }}
                              >
                              </Button>
                            </Popovers>
                          </div>
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
      <Modal
        isStaticBackdrop={true}
        setIsOpen={setAddTeamSize}
        isOpen={addTeamSize}
        size="lg"
        isCentered
      >
        <ModalHeader setIsOpen={setAddTeamSize}>
          <ModalTitle id="addTeamMembers">
          <div>
            {isTeamEdit ? (
              <h5>Edit Team</h5>
            ) : (
              <h5>Add Team</h5>
            )}
          </div>
          </ModalTitle>
          <Button
                        color= {darkModeStatus ? 'dark' : 'light'}
            onClick={() => handleClose()}
            className={darkModeStatus ? 'modal-close-btn-dark' : 'modal-close-btn'}
            icon="Close"
          >
          </Button>
        </ModalHeader>
        <ModalBody>
          <div className="row g-3">
            <div className="col-12 p-2">
              <FormGroup
                id='sysvineRole'
                label='Role'
                isRequired={true}
                labelClassName="label-font-color"
              >
                <Select id="sysvineRole"
                  className="form-select processvine-form-font-weight remove-icon"
                  ariaLabel='Per'
                  onChange={formik.handleChange}
                  value={formik.values.sysvineRole}
                  isValid={formik.isValid && formik.touched.sysvineRole}
                  isTouched={formik.touched.sysvineRole}
                  onBlur={formik.handleBlur}
                  invalidFeedback={formik.errors.sysvineRole}
                >
                  <Option value="">Select Role</Option>
                  {sysvineRoles.map((role: any) => (
                    <option key={role.id} value={role.name}>{role.name}</option>
                  ))}
                </Select>
              </FormGroup>  
            </div>
            <div className="col-12 p-2">
              <FormGroup
                id='numberOfMembers'
                label='No of members'
                isRequired={true}
                labelClassName="label-font-color"
              >
                <Input
                  id="numberOfMembers"
                  type="number"
                  className="form-control processvine-form-font-weight"
                  value={formik.values.numberOfMembers}
                  onBlur={formik.handleBlur}
                  isValid={formik.isValid  && formik.touched.numberOfMembers}
                  isTouched={formik.touched.numberOfMembers}
                  invalidFeedback={formik.errors.numberOfMembers}
                  onChange={formik.handleChange}
                />
              </FormGroup>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="info"
            onClick={() => {
              isTeamEdit ? handleEdit() : handleSave();
              formik.handleSubmit();
            }}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        setIsOpen={setDeleteConfirmationPopup}
        isOpen={deleteConfirmationPopup}
        titleId="Delete Confirmation"
        isStaticBackdrop={true}
      >
        <ModalHeader setIsOpen={setDeleteConfirmationPopup}>
          <ModalTitle id="new-todo-modal">
            Delete Confirmation
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          Are you sure you want to delete?
        </ModalBody>
        <ModalFooter>
            <Button
              color="danger"
              onClick={() => {
                setIsLoading(true);
                handleDelete(deleteTeamId);
                setTeamData();
                setDeleteConfirmationPopup(false);
              }}
            >
              Delete
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button color="success" onClick={() => setDeleteConfirmationPopup(false)}>
              Cancel
            </Button>
        </ModalFooter>
      </Modal>
      {
        alert &&
          <Alert
          icon=''
          isLight
          color='primary'
          borderWidth={0}
          className='cgp-container-alert'
        >
          <AlertHeading tag='h2' className={classNames('text-sm cgp-container-alertHeading')}>
            {alertMessage}
          </AlertHeading>
        </Alert>
      }
    </div>
  )
}

export default TeamsizeTab

