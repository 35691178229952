import React, { FC, useEffect, useRef, useState } from "react";
// @ts-ignore
import Select from "react-select";
import MySelect from "../../components/bootstrap/forms/Select";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Button from "../../components/bootstrap/Button";
import { railsAPI } from "../../types";
import OffCanvas, {
  OffCanvasBody,
  OffCanvasHeader,
  OffCanvasTitle,
} from "../../components/bootstrap/OffCanvas";
import Input from "../../components/bootstrap/forms/Input";
import Textarea from "../../components/bootstrap/forms/Textarea";
// @ts-ignore
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FormikHelpers, useFormik, FormikValues } from "formik";
import axios from "axios";
import {
  getClientRoles,
  getCostTypes,
  getDepartmentList,
  getGroups,
  getStatuses,
  getSysvineRoles,
  getUserGroups,
  updateProjectMemberDetail,
} from "../../common/data/service";
import { getLoggedInUser } from "../../common/data/commonService";
import { IMainUsers } from "../../model/MainUsers";
import DatePickerIcon from "../../components/bootstrap/forms/DatePickerIcon";
import { validateExpiredToken } from "../../App/Constants";
import { useNavigate } from "react-router-dom";
import Spinner from "../../components/bootstrap/Spinner";
import UseColourStyles from "./SelectStyles"
interface ClientRole {
  id: string;
  name: string;
}

interface Status {
  id: string;
  name: string;
}
interface Role {
  value?: string;
  label?: string;
}
interface Group {
  displayName: string;
  name: string;
}


const roles = ["LEAD", "DL", "DH"];

// Define the ProjectMemberPopup component
const ProjectMemberPopup = (props: any) => {
  const colourStyles = UseColourStyles();
  // State for controlling the visibility of the off-canvas component
  const [upcomingEventsEditOffcanvas, setUpcomingEventsEditOffcanvas] = useState(false);

  // Toggle the visibility of the off-canvas component
  const handleUpcomingEdit = () => {
    setUpcomingEventsEditOffcanvas(!upcomingEventsEditOffcanvas);
  };

  // Close the off-canvas component
  const handleOffCanvasClose = () => {
    props.updateProjMemberDetails();
  };

  // State to manage the visibility of an error message
  const [showMessage, setShowMessage] = useState(false);

  // State to indicate loading status
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // State to store client roles data
  const [clientRoles, setClientRoles] = useState<ClientRole[]>([]);

  // State to store Sysvine roles data
  const [sysvineRoles, setSysvineRoles] = useState<ClientRole[]>([]);

  // State to store status data
  const [statuses, setStatuses] = useState<Status[]>([]);

  // State to display an alert message
  const [alert, setAlert] = useState<boolean>(false);

  // State to display validation message
  const [showAlert, setShowAlert] = useState(false);
  const [userDetails, setUserDetails]: any = useState<IMainUsers[]>([]);
  const navigate = useNavigate();
  const [isLoadingDepartment, setIsLoadingDepartment] = useState(false);
  const [isLoadingSysvineRoles, setIsLoadingSysvineRoles] = useState(false);
  const [isLoadingClientRoles, setIsLoadingClientRoles] = useState(false);
  const [isLoadingStatuses, setIsLoadingStatuses] = useState(false);
  const [isLoadingLoggedInUser, setIsLoadingLoggedInUser] = useState(false);
  const [selectedClientRole, setSelectedClientRole] = useState<Role>({});;
  const [costTypes, setCostTypes] = useState<ClientRole[]>([]);

  const [groupsList, setGroupsList] = useState<Group[]>([]);
   const [selectedRole, setSelectedRole] = useState<Role>({});
   const flag = useRef(true);
 	const [isLoadingGroups, setIsLoadingGroups] = useState(false);
 	const [isLoadingUserGroups, setIsLoadingUserGroups] = useState(false);
  const [selectedCostType, setSelectedCostType] = useState<Role>({});;
  useEffect(() => {
    setIsLoadingSysvineRoles(true);
    setIsLoadingClientRoles(true);
    setIsLoadingStatuses(true);
    setIsLoadingLoggedInUser(true);
		setIsLoadingGroups(true);
    getSysvineRoles()
      .then((res) => {
        setSysvineRoles(res?.data);
      })
      .catch((e) => {
        console.log(e.message);
      })
      .finally(() => {
        setIsLoadingSysvineRoles(false);
      });

    
    getClientRoles()
      .then((res) => {
        setClientRoles(res?.data);
      })
      .catch((e) => {
        console.log(e.message);
      })
      .finally(() => {
        setIsLoadingClientRoles(false);
      });

    // Fetch statuses
    
    getStatuses()
      .then((res) => {
        setStatuses(res?.data);
      })
      .catch((e) => {
        console.log(e.message);
      })
      .finally(() => {
        setIsLoadingStatuses(false);
      });

    // Fetch details of the logged-in user
    
    getLoggedInUser()
      .then((res) => {
        setUserDetails(res?.data);
      })
      .catch((e) => {
        if (!validateExpiredToken(e.message)) {
          navigate('/');
          window.location.reload();
        }
      })
      .finally(() => {
        setIsLoadingLoggedInUser(false);
      });

      getCostTypes()
      .then((res) => {
        setCostTypes(res?.data);
      })
      .catch((e) => {
        console.log(e.message);
      })

      getGroups({projectId: props.projectId})
      .then((res) => {
        setGroupsList(res?.data?.groups);
      })
      .catch((e) => {
        if (!validateExpiredToken(e.message)) {
          navigate('/');
          window.location.reload();
        }
      })
      .finally(() => {
        setIsLoadingGroups(false);
      });
  }, [navigate, props.projectId]);

 useEffect(() => {
  setSelectedClientRole({value: clientRoles.find(option => option.name === props.client_role?.name)?.id, label: clientRoles.find(option => option.name === props.client_role?.name ?? "")?.name})
  setSelectedRole({value: sysvineRoles.find(option => option.name === props.sysvine_role?.name)?.id, label: sysvineRoles.find(option => option.name === props.sysvine_role?.name ?? "")?.name})
  setSelectedCostType({value: costTypes.find(option => option.name === props.cost_type?.name)?.id, label: costTypes.find(option => option.name === props.cost_type?.name ?? "")?.name})
}, [sysvineRoles,props,clientRoles, costTypes]);
  // Initialize Formik for form management
  const formik = useFormik({
    async onSubmit<Values>(
      values: FormikValues,
      formikHelpers: FormikHelpers<Values>
    ): Promise<void | Promise<any>> {
      let isValid = false;
      if (values.StartDate === null || values.Status === "" || (props.groupAccess && values.Status !== "Ended" && values.group.length === 0) || (values.Status === "Ended" && !values.EndDate)) {
        setShowAlert(true);
      } else {
        isValid = true;
      }
      if (isValid) {
        setIsLoading(true)
        try {
          if((values?.Status=="Ended" && props?.status?.name!="Ended")){
            values.group = [] 
          }
          const matchingClientRole = clientRoles.find((role) => role.name === values.ClientRole);
          if (matchingClientRole) {
            values.ClientRole = matchingClientRole.id;
          }
          const matchingSysvineRole = sysvineRoles.find((role) => role.name === values.SysvineRole);
          if (matchingSysvineRole) {
            values.SysvineRole = matchingSysvineRole.id;
          }

          // Find and update the selected status based on the name
          const matchingStatus = statuses.find((status) => status.name === values.Status);
          if (matchingStatus) {
            values.Status = matchingStatus.id;
          }

          const matchingCostType = costTypes.find((type) => type.name === values.costType);
          if (matchingCostType) {
            values.costType = matchingCostType.id;
          }
          // Update the project member details
          await updateProjectMemberDetail({
            id: values.id,
            StartDate: values.StartDate,
            EndDate: values.EndDate,
            Description: values.Description,
            ClientRole: values.ClientRole,
            Role: values.Role,
            Status: values.Status,
            ModifiedBy: userDetails.id,
            SysvineRole: values.SysvineRole,
            costType: values.costType,
            group: values.group
          });
          if (isValid) {
            props.handleSearchClick("", "Updated");
            setUpcomingEventsEditOffcanvas(false);
            setIsLoading(false)
          }
          console.log("updated");
          setAlert(true);
          setTimeout(() => {
            setAlert(false);
          }, 3000);

        } catch (error) {

        }
      }
      return undefined;
    },

    // Initial form values
    initialValues: {
      id: props.id,
      Title: props.user.job_title?.jobtitlename ?? "",
      Dept: props.user.department?.deptname ?? "",
      Name: props.user?.userfullname ?? "",
      ClientRole: props.client_role?.name ?? "",
      Status: props.status?.name ?? "",
      SysvineRole: props.sysvine_role?.name ?? "",
      StartDate: props.start_date ? new Date(props.start_date) : "",
      Role: props.project_member_crf,
      EndDate: props.end_date ? new Date(props.end_date) : "",
      Description: props.description ?? "",
      costType: props.cost_type?.name ?? "",
      group: props.groupOptions
    },
  });

  const handleDescriptionChange = (event: { target: { value: any } }) => {
    const inputValue = event.target.value;
    const truncatedValue = inputValue.slice(0, 250);
    formik.setFieldValue('Description', truncatedValue);

    if (inputValue.length < 250) {
      formik.handleChange(event);
      setShowMessage(false);
    } else {
      setShowMessage(true);
    }
  };
  // Automatically open the off-canvas component when the component mounts
  useEffect(() => {
    setUpcomingEventsEditOffcanvas(true);
  }, []);
  const formatDate = (dateString: string) => {
    const timestamp = new Date(dateString);
    const day = timestamp.getDate();
    const month = timestamp.toLocaleString('default', { month: 'short' }).slice(0, 3);
    const year = timestamp.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  };
  const SysvineRoleOptions = sysvineRoles.map((role) => ({
    label: role.name,
    value: role.id.toString(),
  }));
  const ClientRoleOptions = clientRoles.map((role) => ({
    label: role.name,
    value: role.id.toString(),
  }));
  const costTypeOptions = costTypes.map((type) => ({
    label: type.name,
    value: type.id.toString(),
  }));
  const groupOptions = groupsList ? groupsList.map((group) => ({
    label: group.displayName,
    value: group.name,
  })) : [];

  return (
    <>
    {isLoadingSysvineRoles || isLoadingClientRoles || isLoadingStatuses || isLoadingLoggedInUser || isLoadingGroups || isLoadingUserGroups || isLoading ? (
      <div className="processvine-overlay-box">
        <Spinner
          className="text-center processvine-no-projects processvine-spinner"
          inButton
        />
      </div>
    ) : null}
    <OffCanvas
      setOpen={setUpcomingEventsEditOffcanvas}
      isOpen={upcomingEventsEditOffcanvas}
      onClose={handleOffCanvasClose}
      titleId="upcomingEdit"
      isBodyScroll
      placement="end"
    >
      <OffCanvasHeader setOpen={setUpcomingEventsEditOffcanvas}>
        <OffCanvasTitle id="...">
          {props.viewMember ? "Project Member Details" : "Update Project Member Form"}
        </OffCanvasTitle>
      </OffCanvasHeader>
      <OffCanvasBody className="...">
        <div className="row g-4">
          <div className="col-12">
            <FormGroup label="Department">
              <Input
                className="processvine-form-font-weight input-box-shadow-unset"
                id="Dept"
                onChange={formik.handleChange}
                value={formik.values.Dept}
                readOnly
              />
            </FormGroup>
          </div>
          <div className="col-12">
            <FormGroup label="Name">
              <Input
                className="processvine-form-font-weight input-box-shadow-unset"
                id="Name"
                onChange={formik.handleChange}
                value={formik.values.Name}
                readOnly
              />
            </FormGroup>
          </div>

          {!props.viewMember ? (
            <div className="col-12">
                           <FormGroup id="Sysvine Role" label="Role">
                <Select
                  options={[{ value: null, label: 'Please Select' }, ...SysvineRoleOptions]}
                  isSearchable={true}
                  placeholder="Search and choose..."
                  
                  value={selectedRole.value ? {label: selectedRole.label,value: selectedRole.value} : null}

                  onChange={(selectedOption: {
                    label: string; value: any }) => {
                    if (selectedOption) {
                      setSelectedRole({value: selectedOption.value, label: selectedOption.label})
                      formik.setFieldValue("SysvineRole", selectedOption.value);
                    } else {
                      console.log("No option selected.");
                    }
                  }}
                  styles={colourStyles}
                />
              </FormGroup>
            </div>
          ) : (
            <div className="col-12">
              <FormGroup label="Role">
                <Input
                  className="processvine-form-font-weight input-box-shadow-unset"
                  id="SysvineRole"
                  value={formik.values.SysvineRole}
                  readOnly={true}
                />
              </FormGroup>
            </div>
          )}
                    {!props.viewMember ? (
            <div className="col-12">
              <FormGroup id="ClientRole" label="Client Role">
                <Select
                options = {[{ value: null, label: 'Please Select' }, ...ClientRoleOptions]}
                isSearchable={true}
                placeholder="Search and choose..."
                
                value={selectedClientRole.value ? {label: selectedClientRole.label,value: selectedClientRole.value} : null}

                onChange={(selectedOption: {
                  label: string; value: any 
}) => {

                  if (selectedOption) {
                    setSelectedClientRole({value: selectedOption.value, label: selectedOption.label})
                    formik.setFieldValue("ClientRole", selectedOption.value);
                  } else {
                    console.log("No option selected.");
                  }
                }}
                styles={colourStyles}
                />
              </FormGroup>
            </div>
          ) : (
            <div className="col-12">
              <FormGroup label="Client Role">
                <Input
                  className="processvine-form-font-weight input-box-shadow-unset"
                  id="ClientRole"
                  value={formik.values.ClientRole}
                  readOnly={true}
                />
              </FormGroup>
            </div>
          )}
          {!props.viewMember ? (
            <div className="col-12">
              <FormGroup label="Cost Type">
                <Select
                  isSearchable={true}
                  placeholder="Search and choose..."
                  options={[{ value: null, label: 'Please Select' }, ...costTypeOptions]}
                  value={selectedCostType.value ? { label: selectedCostType.label, value: selectedCostType.value } : null}

                  onChange={(selectedOption: {
                    label: string; value: any
                  }) => {

                    if (selectedOption) {
                      setSelectedCostType({ value: selectedOption.value, label: selectedOption.label })
                      formik.setFieldValue("costType", selectedOption.value);
                    } else {
                      console.log("No option selected.");
                    }
                  }}
                  styles={colourStyles}
                />
              </FormGroup>
            </div>
          ) : (
            <div className="col-12">
              <FormGroup label="Cost Type">
                <Input
                  className="processvine-form-font-weight input-box-shadow-unset"
                  id="costType"
                  value={formik.values.costType}
                  readOnly={true}
                />
              </FormGroup>
            </div>
          )}
          {!props.viewMember && props.groupAccess ? (
            <div className="col-12">
              <FormGroup id="Google Groups" label="Google Groups" isRequired={formik.values.Status != "Ended"} >
              <Select
  closeMenuOnSelect={false}
  options={groupOptions}
  isSearchable={true}
  isMulti
  isClearable={false}
  value={formik.values.group}
  placeholder="Search and choose..."
  onChange={(selectedOption: any) => {
    formik.setFieldValue("group", selectedOption || []);
  }}
  styles={colourStyles}
/>
              </FormGroup>
            </div>
          ) : props.groupAccess ? (
            <div className="col-12">
              <FormGroup label="Google Groups">
                <Textarea
                  className="processvine-form-font-weight input-box-shadow-unset"
                  value={props.displayNameArray.join(", ")}
                  readOnly={true}
                  rows={2}
                />
              </FormGroup>
            </div>
          ) : null}

          {props.viewMember ? (
            <div className="col-12">
              <FormGroup label="Start Date">
                <Input
                  className="processvine-form-font-weight input-box-shadow-unset"
                  id="StartDate"
                  type="text"
                  value={formik.values.StartDate ? formatDate((formik.values.StartDate).toString()) : ''}
                  readOnly={true}
                />
              </FormGroup>
            </div>
          )
            : (
              <div className="col-12">
                <FormGroup label="Start Date" isRequired={true} className="datepicker-container">
                  <DatePicker
                    showIcon
                    className="processvine-form-font-weight form-control"
                    selected={formik.values.StartDate ? new Date(formik.values.StartDate) : null}

                    maxDate={formik.values.EndDate}
                    onChange={(date: any) => {
                      console.log("Received date:", date);
                      formik.setFieldValue("StartDate", date);
                    }}
                    dateFormat="dd-MMM-yyyy"
                    placeholderText="Click to select a date"
                    icon={<DatePickerIcon />}
                  />
                </FormGroup>
              </div>
            )}

          <div className="col-12">
            <FormGroup label="End Date" className="datepicker-container" isRequired={formik.values.Status === "Ended"}>
              {props.viewMember ? (
                <Input
                  className="processvine-form-font-weight input-box-shadow-unset"
                  type="text"
                  value={formik.values.EndDate ? formatDate((formik.values.EndDate).toString()) : ''}
                  readOnly={true}
                />
              ) : (
                <DatePicker
                  showIcon
                  className="processvine-form-font-weight form-control"
                  selected={formik.values.EndDate}
                  onChange={(date: any) => formik.setFieldValue("EndDate", date)}
                  dateFormat="dd-MMM-yyyy"
                  minDate={formik.values.StartDate}
                  placeholderText="Click to select a date"
                  icon={<DatePickerIcon />}
                />
              )}
            </FormGroup>
          </div>
          {!props.viewMember ? (
            <div className="col-12">
              <FormGroup id="Status" label="Status" isRequired={true}>
                <MySelect
                  className="processvine-form-font-weight"
                  ariaLabel="Status"
                  placeholder="Select Status"
                  onChange={formik.handleChange}
                  list={[
                    { value: "", text: "Please Select" },
                    ...statuses.map((status) => ({
                      value: status.name,
                      text: status.name,
                    })),
                  ]}
                  value={formik.values.Status}
                />
              </FormGroup>
            </div>
          ) : (
            <div className="col-12">
              <FormGroup label="Status">
                <Input
                  id="Status"
                  className="processvine-form-font-weight input-box-shadow-unset"
                  value={formik.values.Status}
                  readOnly={true}
                />
              </FormGroup>
            </div>
          )}
          
          {props.viewMember ? (
            <div className="col-12">
              <FormGroup label={`Description`}>
                <Textarea
                  className="processvine-form-font-weight input-box-shadow-unset"
                  rows={8}
                  id="Description"
                  name="Description"
                  maxLength={250}
                  onChange={handleDescriptionChange}
                  value={formik.values.Description}
                  readOnly={true}
                />
              </FormGroup>
            </div>
          )
            : (
              <div className="col-12">
                <FormGroup label={`Description (Note: Max 250 Characters allowed)`}>
                  <Textarea
                    className="processvine-form-font-weight"
                    rows={8}
                    id="Description"
                    name="Description"
                    maxLength={250}
                    onChange={handleDescriptionChange}
                    value={formik.values.Description}
                  />

                </FormGroup>
                {showMessage && <p className='description-validation'>Exceeded the 250 Characters limit.</p>}
              </div>

            )}
        </div>
      </OffCanvasBody>
      {showAlert && (
        <span className="kpi-container-message"> {"Please Fill Mandatory Fields(*)"} </span>
      )}
      <div>
        {!props.viewMember && (
          <>
          <div className='row m-0'>
                    <div className='col-12 p-3'>
            <Button
              icon="save"
              className="w-100 btn-light-info"

              onClick={() => {
                if (formik.dirty) {
                  formik.handleSubmit();
                }
              }}
              isDisable={!formik.dirty}
            >
              Update
            </Button>
            </div>
            </div>
          </>
        )}
      </div>
    </OffCanvas>
    </>
  );
};

export default ProjectMemberPopup;