import { envVariable } from "../types";

export const getAvatarUrl = (text: any) => {
	return `https://avatars.dicebear.com/api/avataaars/${text}.svg`;
};

export const isAdmin = (keycloak: any) => {
	return (
		keycloak &&
		keycloak.tokenParsed &&
		keycloak.tokenParsed.resource_access['movies-app'] &&
		keycloak.tokenParsed.resource_access['movies-app'].roles.includes('MOVIES_MANAGER')
	);
};

export const handleLogError = (error: any) => {
	if (error.response) {
		console.log(error.response.data);
	} else if (error.request) {
		console.log(error.request);
	} else {
		console.log(error.message);
	}
};
export const isRKVNEnabled = () => {
	return true;
}
export const isOcpEnabled = () => {
	return true
}
export const isCGPEnabled = () => {
	return true;
}
export const isSCEnabled = () => {
	return true;
}
export const getEnvVariable = () => {
	return envVariable ? envVariable : ""

}