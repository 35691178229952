import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Page from "../../layout/Page/Page";
import PageWrapper from "../../layout/PageWrapper/PageWrapper";
import { getSelectedProjectDetails } from "../../common/data/service";
import useDarkMode from "../../hooks/useDarkMode";
import TeamsizeTab from "./TeamsizeTab";
import UrlTab from './UrlTab';
import ArchComp from './ArchComp';
import ScreenshotTab from './ScreenshotTab';
import Spinner from '../../components/bootstrap/Spinner';
import Description from "./Description";
import Technologies from "./Technologies";
import { validateExpiredToken } from '../../App/Constants';

const AedBase = (props: any) => {
  const { projectId, aedAccess } = props;
  const [urlDetails, setUrlDetails]= useState([]);
  const flag = useRef(true);
  const [teamDetails, setTeamDetails] = useState<{ roles: { role: string; number: string }[] }>({ roles: [] });
  const [archCompDetails, setArchCompDetails] = useState<{ architecture_components: { role: string; number: string }[] }>({ architecture_components: [] });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [aedConstant, setAedConstant] = useState({});
  const [projectDetailsValues, setProjectDetailsValues] = useState({});
  const [isEditButtonClicked, setIsEditButtonClicked] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleEditButtonClick = (value: any) => {
    setIsEditButtonClicked(value);
  };
  useEffect(() => {
        if(flag.current){
            flag.current = false;
      setIsLoading(true);
            getSelectedProjectDetails({ projectId }).then((res) => {
        setTeamDetails(res?.data?.data[0]?.aed_team_json ?? []);
        setUrlDetails(res?.data?.data[0]?.aed_urls_json ?? []);
        setArchCompDetails(res?.data?.data[0]?.aed_arch_json ?? []); 
        setAedConstant(res?.data?.data[0]?.aed_constants ?? {});
        setProjectDetailsValues(res)
        setIsLoading(false);
        }).catch((e) => {
      if (!validateExpiredToken(e.message)) {
        navigate('/');
        window.location.reload();
      }
    });
  }
    const disableSelection = (e: Event) => {
      e.preventDefault();
    };
    if (isEditButtonClicked) {
      document.removeEventListener('selectstart', disableSelection);
      document.removeEventListener('contextmenu', disableSelection);
      document.body.style.overflow = 'hidden';
    } else {
      document.addEventListener('selectstart', disableSelection);
      document.addEventListener('contextmenu', disableSelection);
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.removeEventListener('selectstart', disableSelection);
      document.removeEventListener('contextmenu', disableSelection);
    }; 
    }, [projectId, isLoading, isEditButtonClicked, navigate])  
  return (
    <PageWrapper title={"AED"} className="aed-page-wrapper">
      <Page>
        <div className="row">
          <div className='class="col-xxl-4 col-xl-12'>
            <Description
              projectId={projectId}
              projectDetailsValues={projectDetailsValues}
              setIsLoading={setIsLoading}
              aedAccess={aedAccess}
              handleEditButtonClick={handleEditButtonClick}
            />
            <ArchComp
              projectId={projectId}
              archCompDetails={archCompDetails}
              setArchCompDetails={setArchCompDetails}
              setIsLoading={setIsLoading}
              aedConstant={aedConstant}
              aedAccess={aedAccess}
            />
            <Technologies
              projectId={projectId}
              projectDetailsValues={projectDetailsValues}
              setIsLoading={setIsLoading}
              aedAccess={aedAccess}
            />
            <ScreenshotTab
              projectId={projectId}
              setIsLoading={setIsLoading}
              aedConstant={aedConstant}
              aedAccess={aedAccess}
            />
            <TeamsizeTab
              projectId={projectId}
              teamDetails={teamDetails}
              setTeamDetails={setTeamDetails}
              setIsLoading={setIsLoading}
              aedAccess={aedAccess}
            />
            <UrlTab
              projectId={projectId}
              setUrlDetails={setUrlDetails}
              urlDetails={urlDetails}
              setIsLoading={setIsLoading}
              aedConstant={aedConstant}
              aedAccess={aedAccess}
            />
          </div>
          {isLoading && (
            <div className="processvine-overlay-box">
              <Spinner
                className="text-center processvine-no-projects processvine-spinner"
                inButton
              />
            </div>
          )}
        </div>
      </Page>
    </PageWrapper>
  );
};

export default AedBase;
