import React, { useState, useEffect, forwardRef, HTMLAttributes, CSSProperties  } from "react";
import { getAedScreenshots, removeAedScreenshot, saveAedScreenshot, updateAedScreenshot } from "../../common/data/service";
import { FileUploader } from "react-drag-drop-files";
import Button from "../../components/bootstrap/Button";
import useDarkMode from "../../hooks/useDarkMode";
import classNames from "classnames";
import Icon from "../../components/icon/Icon";
import { DndContext, DragOverlay, PointerSensor, useSensor, useSensors, DragStartEvent, TouchSensor, closestCenter } from "@dnd-kit/core"
import { SortableContext, arrayMove, rectSortingStrategy } from "@dnd-kit/sortable";
import Modal, { ModalBody, ModalHeader, ModalTitle, ModalFooter } from "../../components/bootstrap/Modal";
import { ScreenshotImages, ImageModal } from "./types";
import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";
import ImageViewer from 'react-simple-image-viewer';
import { useToasts } from 'react-toast-notifications';
import { useFormik } from "formik";
import Spinner from '../../components/bootstrap/Spinner';
import Alert, { AlertHeading } from '../../components/bootstrap/Alert';
import { validateExpiredToken } from '../../App/Constants';
import { useNavigate } from 'react-router-dom';

const ImageViewModal: React.FC<ImageModal> = ({ imageUrl, onClose, currentImage }) => {
  return (
    <div className='image-view-modal'>
      <ImageViewer
        src={imageUrl}
        currentIndex={currentImage}
        disableScroll={true}
        onClose={onClose}
        closeOnClickOutside={true}
        backgroundStyle={{ backgroundColor: 'rgba(0,0,0,0.9)' }}
      />
    </div>
  );
};
const Item = forwardRef<HTMLDivElement, {
  item: ScreenshotImages;
  isOpacityEnabled?: boolean;
  isDragging?: boolean;
} & HTMLAttributes<HTMLDivElement>>(
    function Item({ item, isOpacityEnabled, isDragging, style, ...props }, ref) {
    const styles: CSSProperties = {
      opacity: isOpacityEnabled ? "0.4" : "1",
      cursor: isDragging ? "grabbing" : "grab",
      lineHeight: "0.5",
      transform: isDragging ? "scale(1.05)" : "scale(1)",
      ...style
    };
    return (
      <div ref={ref} style={styles} {...props} className="ratio ratio-1x1 rounded-2 border-0 overflow-hidden">
        <img
          src={item.url}
          alt={`${item.id}`}
          className={isDragging ? "sorted-image" : "sortable-image"}
        />
      </div>
    );
  }
);
const SortableItem = ({ item, ...props }: { item: ScreenshotImages } & HTMLAttributes<HTMLDivElement>) => {
  const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({
    id: item.id,
  });
  const styles = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
  };
  return (
    <Item
      item={item}
      ref={setNodeRef}
      style={styles}
      isOpacityEnabled={isDragging}
      {...props}
      {...attributes}
      {...listeners}
    />
  )
};
const ScreenshotTab = (props: any) => {
  const { projectId, setIsLoading, aedConstant, aedAccess } = props;
  const imageSize = aedConstant?.SCREENSHOTS?.IMAGE_SIZE || 0;
  const imageType = aedConstant?.SCREENSHOTS?.IMAGE_TYPE || [];
  const { darkModeStatus } = useDarkMode();
    const [fetchedImage, setFetchedImage] = useState([]);
  const [image, setImage] = useState<File | null>();
  const [preview, setPreview] = useState<string | null>();
  const sensors = useSensors(useSensor(PointerSensor), useSensor(TouchSensor));
  const [activeItem, setActiveItem] = useState<ScreenshotImages>();
  const [items, setItems] = useState<ScreenshotImages[]>([])
  const [imageUrls, setImageUrls] = useState<[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [deleteConfirmationPopup, setDeleteConfirmationPopup] = useState<boolean>(false);
  const [deleteImagePath, setDeleteImagePath] = useState<string | undefined>();
  const [editScreenshot, setEditScreenshot] = useState(false);
  const [deleteImageId, setDeleteImageId] = useState<number | undefined>();
  const { addToast } = useToasts();
  const supportedImageType = imageType && imageType.join(", ").toLowerCase();
  const [isScreenshotLoading, setIsScreenshotLoading] = useState<boolean>(false);
  const [addScreeshot, setAddScreenshot] = useState<boolean>(false);
  const [alert, setAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const navigate = useNavigate();
  const formik = useFormik({
    onSubmit: (values) => {
      setIsLoading(true)
      saveAedScreenshot({
        projectId: projectId,
        screenshots: values.screenshots,
      }).catch((e) => {
        if (!validateExpiredToken(e.message)) {
          navigate('/');
          window.location.reload();
        }
      });
    },
        initialValues: {
            projectId: "",
            screenshots: {},
        }
    });
    const fetchImage = () => {
    setIsLoading(true);
    getAedScreenshots(projectId).then((res) => {
      const fetchedData = res?.data || [];
      setFetchedImage(fetchedData);
      setItems(fetchedData);
      const urls = fetchedData.map((item: ScreenshotImages) => item.url);
      setImageUrls(urls);
      setIsLoading(false);
    });
    setAlert(true);
    setTimeout(() => {
      setAlert(false);
    }, 3000)
    };
    const deleteImage = (path:  string) => {
        removeAedScreenshot({projectId: projectId, imagePath: path, screenshotId: deleteImageId}).catch((e) => {
      if (!validateExpiredToken(e.message)) {
        navigate('/');
        window.location.reload();
      }
    });
    setAlertMessage('Deleted Successfully');
    };
  const delay = (ms: number | undefined) => new Promise(
    resolve => setTimeout(resolve, ms)
  );
  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event
    setActiveItem(items.find((item) => item.id === active.id))
  };
  const handleDragEnd = async (event: any) => {
    const { active, over } = event;
    if (!over || !active) return;
    const activeId = active.id;
    const overId = over.id;
    const activeIndex = fetchedImage.findIndex((img: any) => img.id === activeId);
    const overIndex = fetchedImage.findIndex((img: any) => img.id === overId);
    if (activeIndex > -1 && overIndex > -1 && activeIndex !== overIndex) {
      const updatedImages = arrayMove(fetchedImage, activeIndex, overIndex);
      setFetchedImage(updatedImages);
      setItems(updatedImages);
      await updateAedScreenshot({
        projectId: projectId,
        aedScreenshots: updatedImages,
      });
      setAlertMessage('Updated Successfully');
      setAlert(true);
      setTimeout(() => {
        setAlert(false);
      }, 3000)
    }
  };

  useEffect(() => {
    if (projectId) {
      setIsScreenshotLoading(true)
      getAedScreenshots(projectId).then((res) => {
        const fetchedData = res?.data || [];
        setFetchedImage(fetchedData);
        setItems(fetchedData);
        const urls = fetchedData.map((item: ScreenshotImages) => item.url);
        setImageUrls(urls);
        setIsScreenshotLoading(false)
      });
    }
    (deleteConfirmationPopup) ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'unset';
  }, [projectId, setIsLoading, deleteConfirmationPopup]);

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(image);
    } else {
      setPreview(null);
    }
  }, [image]);

  return (
    <div>
      <div className="card">
        <div className="card-header">
          <div className="row g-3 align-items-center">
            <div className="col d-flex align-items-center">
              <div className="flex-shrink-0">
                <div className="ratio ratio-1x1 aed-icon-box">
                  <div className={classNames("rounded-2 d-flex align-items-center justify-content-center", darkModeStatus ? "bg-lo25-primary" : "bg-l10-primary")}>
                    <label
                      className="border-0 bg-transparent me-0"
                      htmlFor="Image">
                      <Icon icon="Image" size="2x" color='primary' />
                    </label>
                  </div>
                </div>
              </div>
              <div className="flex-grow-1 ms-3 d-flex justify-content-between align-items-center">
                <div>
                  <div className="fw-bold fs-6 mb-0">
                    <h5 className="card-title">Images</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-actions">
            {!editScreenshot && aedAccess?.edit && (
              <Button
                icon={addScreeshot ? 'Close' : 'Plus'}
                color="info"
                onClick={() => {
                  setAddScreenshot(!addScreeshot);
                  setPreview(null);
                  setImage(null);
                  formik.setFieldValue("screenshots", {});
                }}>
                  {addScreeshot ? 'Close' : 'Add'}
              </Button>
            )}
            {aedAccess?.edit && <Button
              isOutline={!darkModeStatus}
              color={"dark"}
              isLight={darkModeStatus}
              className={classNames("text-nowrap", {
                "border-light": !darkModeStatus,
              })}
              icon={editScreenshot ? "Close":"Edit"}
              onClick={()=>{
                setEditScreenshot(!editScreenshot);
                if (!editScreenshot && addScreeshot){
                  setAddScreenshot(!addScreeshot)
                }
              }}
              isDisable={!editScreenshot && (!imageUrls || imageUrls.length === 0)}
            >
            </Button>}
          </div>
        </div>
        <div className="card-body">
          {addScreeshot &&
            <div className="aed-card justify-content-center">
              <form onSubmit={formik.handleSubmit} encType="multipart/form-data" className="w-100 h-100 mb-4">
                <div className="card shadow-none border border-1 rounded-2 p-5 m-lg-1 align-items-center">
                  <FileUploader
                    name="file"
                    types={imageType}
                    onTypeError={() => { addToast("Invalid file type!", { appearance: "error", autoDismiss: true }) }}
                    handleChange={(event: any) => {
                      formik.setFieldValue("screenshots", event)
                      event && event.type.substring(0, 5) === "image" ? setImage(event) : setImage(null);
                    }}
                    maxSize={imageSize}
                    onSizeError={()=> { addToast(`File size exceed ${imageSize}MB`, { appearance: "error", autoDismiss: true }) }}
                  >
                    { preview ? (
                      <div className="flex-grow-1 ms-0 shadow-3d-dark image-preview-modal">
                        <img src={preview} alt="Image not available"/>
                      </div>
                      ) : (
                        <div className="flex-grow-1 ms-3">
                          <div className="d-flex justify-content-center">
                            <label
                              className="border-0 bg-transparent cursor-pointer me-0"
                              htmlFor="CloudUpload">
                              <Icon icon="CloudUpload" size="5x" color="info"/>
                            </label>
                          </div>
                          <div className="d-flex justify-content-center fw-bold fs-8 mb-0">
                            Drag & Drop
                          </div>
                          <div className="d-flex justify-content-center fw-bold fs-8 mb-0">
                            or browse
                          </div>
                          <div className="d-flex justify-content-center text-muted">
                            {`Supports ${supportedImageType}`}
                          </div>
                          <div className="d-flex justify-content-center text-muted">
                            {`Maximum size ${imageSize} MB`}
                          </div>
                        </div>
                      )
                    }
                  </FileUploader>
                </div>
              </form>
            </div>
          }
          {preview && addScreeshot &&
            <div className="upload-btn-modal">
              <Button
                className="btn image-upload-btn"
                isLight
                color="danger"
                onClick={() => {
                  setPreview(null);
                  setImage(null);
                  formik.setFieldValue("screenshots", {});
                }}
              >
                Cancel
              </Button>
              <Button
                className="btn btn-light-info"
                color="info"
                isLight
                onClick={async () => {
                  formik.handleSubmit();
                  await delay(1000);
                  formik.setFieldValue("screenshots", {});
                  fetchImage();
                  setPreview(null);
                  setImage(null);
                  setAlertMessage('Saved Successfully');
                }}
              >
                Upload
              </Button>
            </div>
          }
          {editScreenshot ? (
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
            >
              <SortableContext items={items} strategy={rectSortingStrategy}>
                <div className={fetchedImage.length ? "sortable-image-modal" : "aed-card"}>
                  {items.map((item) => (
                    <div  key={item.id} className="d-flex flex-column justify-content-between align-items-end h-100 position-relative">
                      <SortableItem item={item}/>
                      <Button
                        color="danger"
                        className={classNames("sortable-image-button", "text-nowrap", "rounded-circle", {
                          "border-light": !darkModeStatus,
                        })}
                        icon="Close"
                        onClick={async () => {
                          setDeleteImagePath(item.path);
                          setDeleteImageId(item.id);
                          setDeleteConfirmationPopup(true);
                        }}
                      >
                      </Button>
                    </div>
                  ))}
                </div>
              </SortableContext>
              <DragOverlay adjustScale className="drag-overlay-modal">
                {activeItem ? <Item item={activeItem} isDragging /> : null}
              </DragOverlay>
            </DndContext>
          ) : (
            <div
            className={classNames(fetchedImage.length ? "sortable-image-modal-view" : "aed-card")}>
              {Object.keys(fetchedImage).length > 0 && fetchedImage.map((img: any, index: number) => (
                <div
                  key={img.id}
                  className="ratio ratio-1x1 rounded-2 border-0 overflow-hidden"
                >
                  <img
                    src={img.url}
                    alt={`Image ${img.id}`}
                    className="uploaded-image-modal"
                    onClick={() => 
                      {
                        setCurrentImage(index)
                        setIsModalOpen(true)
                      }
                    }
                  />
                </div>
              ))}  
            </div>
          )}
          {isModalOpen &&
            <ImageViewModal
              imageUrl={imageUrls}
              onClose={() => setIsModalOpen(false)}
              currentImage={currentImage}
            />
          }
        </div>
      </div>
      <Modal
        setIsOpen={setDeleteConfirmationPopup}
        isOpen={deleteConfirmationPopup}
        titleId="Delete Confirmation"
      >
        <ModalHeader setIsOpen={setDeleteConfirmationPopup}>
          <ModalTitle id="new-todo-modal">
            Delete Confirmation
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
            Are you sure you want to delete?
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={async () => {
              setIsLoading(true);
              deleteImagePath !== undefined && deleteImage(deleteImagePath);
              await delay(1000);
              fetchImage();
              setDeleteConfirmationPopup(false);
              setIsLoading(false);
            }}
          >
            Delete
          </Button>
          &nbsp;
          <Button color="success" onClick={() => setDeleteConfirmationPopup(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      {
        alert &&
          <Alert
          icon=''
          isLight
          color='primary'
          borderWidth={0}
          className='cgp-container-alert'
        >
          <AlertHeading tag='h2' className={classNames('text-sm cgp-container-alertHeading')}>
            {alertMessage}
          </AlertHeading>
        </Alert>
      }
      {isScreenshotLoading && (
        <div className="processvine-overlay-box">
          <Spinner
            className="text-center processvine-no-projects processvine-spinner"
            inButton
          />
        </div>
      )}
    </div>
  )
}

export default ScreenshotTab
